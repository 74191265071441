import React from "react";
import CountChart from "../charts/CountChart";
import QuestionPanel from "../util/QuestionPanel";
import MetricYesNoChart from "../charts/MetricYesNoChart";

function Data(props) {
    return (
        <div>
            <QuestionPanel question="39">
                <CountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="40">
                <CountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="41">
                <CountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="42">
                <CountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="43">
                <CountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="44">
                <CountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="45">
                <CountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="46">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="47">
                <CountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="48">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="49">
                <CountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="50">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
        </div>
    );
}

export default Data;
