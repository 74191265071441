import React from "react";

export default function Downloader(props) {
    let url = props.json ? "/api/metrics/json?" : "/api/metrics/csv?";
    if (props.question) {
        url = url + "&question=" + props.question
    }
    if (props.region) {
        url = url + "&region=" + props.region
    }
    return <span className={props.className}>
        <a href={ url } target="_blank"><button className="appearance-none rounded-none py-2 px-3 outline-none leading-tight border-0 bg-secondary hover:bg-secondary-lighter text-white">{ props.json ? "Download JSON" : "Download CSV"}</button></a>
    </span>
}