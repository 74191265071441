import React from "react";

export default function Methodology() {
    return <div>
        <h1 className="mt-5 mb-4 font-bold">Methodology</h1>
        <p>A suite of complementary approaches and methods was used to underpin the information presented and discussed in the Global Ocean Science Report 2020 (GOSR2020). The chosen methodologies allow information to be captured about different aspects of ocean science, including research funding, human and technical capacities and outputs (e.g. publications and patents), as well as supporting organizations, infrastructures and facilities.</p>
        <p>A full description of the applied definitions, data collection and data analysis can be found in Chapter 2 of the GOSR2020.</p>

        <p>The GOSR2020 questionnaire developed for the majority of the data collection can be accessed here:</p>
        <ul>
            <li><a href="/files/PDF GOSR-II questionnaire_complete_eng.pdf" target="_blank" rel="noopener noreferrer">The Global Ocean Science Report 2020 Questionnaire (PDF in English)</a></li>
            <li><a href="/files/PDF GOSR-II questionnaire_complete_fr.pdf" target="_blank" rel="noopener noreferrer">Questionnaire du Rapport mondial sur les sciences océaniques 2020 (PDF in French)</a></li>
            <li><a href="/files/PDF GOSR-II questionnaire_complete_es.pdf" target="_blank" rel="noopener noreferrer">Cuestionario del Informe Mundial sobre las Ciencias Oceánicas 2020 (PDF in Spanish)</a></li>
        </ul>
    </div>
}