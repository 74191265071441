import React from "react";
import DataModal from "./DataModal";
import questions from "../../util/questions";

function QuestionPanel(props) {
    return (
        <div className="flex flew-row w-full mt-6">
            <div className="w-2/5 pr-6">
                {
                    questions[props.question].heading && <h3>{questions[props.question].heading}</h3>
                }
                {
                    questions[props.question].title && <p>{questions[props.question].title}</p>
                }
                {
                    questions[props.question].abstract &&
                    questions[props.question].abstract.map((a, index) => <p key={index}>{a}</p>)
                }
                <DataModal className="mt-6" region={props.region} question={props.question} />
            </div>
            <div className="w-3/5 pl-4">
                {React.cloneElement(props.children, { question: props.question })}
            </div>
        </div>
    )
}

export default QuestionPanel;
