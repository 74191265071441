import React from "react";
import { Link, useLocation } from "react-router-dom";

function ThemeNavigation(props) {
    const location = useLocation();
    return (
        <div>
            <div className="mt-8 w-full">
                {
                    props.themes.map((theme, index) => {
                        const path = "/theme/" + theme.id;
                        if (location.pathname === path) {
                            return <Link key={index} to={path} className="inline-block appearance-none rounded-none py-3 px-4 mr-2 mb-2 outline-none leading-tight bg-primary border border-primary text-white hover:bg-primary hover:text-white cursor-pointer">{ theme.name }</Link>
                        } else {
                            return <Link key={index} to={path} className="inline-block appearance-none rounded-none py-3 px-4 mr-2 mb-2 outline-none leading-tight bg-white border border-primary text-primary hover:bg-primary hover:text-white cursor-pointer">{ theme.name }</Link>
                        }
                    })
                }
            </div>
        </div>
    );
}

export default ThemeNavigation;
