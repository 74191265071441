import React, {useEffect, useState} from "react";
import Select from "./components/util/Select";
import ResearchCapacity from "./components/themes/ResearchCapacity";
import years from "./util/years";
import themes from "./util/themes";
import ThemeNavigation from "./components/navigation/ThemeNavigation";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GeneralInformation from "./components/themes/GeneralInformation";
import Methodology from "./components/pages/Methodology";
import Report from "./components/pages/Report";
import Home from "./components/pages/Home";
import MainNavigation from "./components/navigation/MainNavigation";
import DataAccess from "./components/pages/DataAccess";
import Spending from "./components/themes/Spending";
import Data from "./components/themes/Data";
import { Link, Redirect } from "react-router-dom";
import CapacityDevelopment from "./components/themes/CapacityDevelopment";
import SustainableDevelopment from "./components/themes/SustainableDevelopment";

function App() {

    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState(1);
    const [year, setYear] = useState(2017);

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch("/api/regions");
            const data = await res.json();
            setRegions(data.results);
        };
        fetchData();
    }, [setRegions]);

    function handleRegionChange(e) {
        setRegion(e.target.value);
    }

    function handleYearChange(e) {
        setYear(e.target.value);
    }

    return (
        <Router>
            <div className="grid">
                <div className="header flex justify-between">
                    <h1>
                        <img alt="IOC logo" src="/ioc.png" />
                        <Link to="/">Global Ocean Science Report</Link>
                    </h1>
                    <MainNavigation/>
                </div>
                <div className="banner">
                </div>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/home" />
                    </Route>
                    <Route exact path="/home">
                        <div className="main w-full">
                            <Home/>
                        </div>
                    </Route>
                    <Route exact path="/methodology">
                        <div className="main w-full">
                            <Methodology/>
                        </div>
                    </Route>
                    <Route exact path="/data">
                        <div className="main w-full">
                            <DataAccess/>
                        </div>
                    </Route>
                    <Route exact path="/report">
                        <div className="main w-full">
                            <Report/>
                        </div>
                    </Route>
                    <Route path="/theme">
                        <div className="main w-full">
                            <div className="mt-8 flex flex-row">
                                <ThemeNavigation themes={themes} />
                            </div>
                            <div className="mt-8 mb-4 flex flex-row">
                                <Select className="mr-6" value={region} options={regions} onChange={handleRegionChange} />
                                <Select value={year} options={years} onChange={handleYearChange} />
                            </div>
                            <Switch>
                                <Route exact path="/theme">
                                    <Redirect to="/theme/research" />
                                </Route>
                                <Route path="/theme/general">
                                    <GeneralInformation year={year} region={region} />
                                </Route>
                                <Route path="/theme/research">
                                    <ResearchCapacity year={year} region={region} />
                                </Route>
                                <Route path="/theme/spending">
                                    <Spending year={year} region={region} />
                                </Route>
                                <Route path="/theme/data">
                                    <Data year={year} region={region} />
                                </Route>
                                <Route path="/theme/capacity">
                                    <CapacityDevelopment year={year} region={region} />
                                </Route>
                                <Route path="/theme/sustainable">
                                    <SustainableDevelopment year={year} region={region} />
                                </Route>
                            </Switch>
                        </div>
                    </Route>
                </Switch>
                <div className="footer">
                </div>
                <div className="footer-center">
                    <img alt="IOC logo" src="/decade.png" className="logo" />
                </div>
            </div>
        </Router>
    );
}

export default App;
