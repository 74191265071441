import React from "react";
import SumBarChart from "../charts/SumBarChart";
import BarChart from "../charts/BarChart";
import MetricYesNoChart from "../charts/MetricYesNoChart";
import QuestionPanel from "../util/QuestionPanel";

function Spending(props) {
    return (
        <div>

            <QuestionPanel question="14">
                <SumBarChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="15">
                <SumBarChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="16">
                <BarChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="17">
                <BarChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="18">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="19">
                <SumBarChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="20">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="21">
                <SumBarChart region={props.region} year={props.year} />
            </QuestionPanel>

        </div>
    );
}

export default Spending;
