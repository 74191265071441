import React from "react";
import CountChart from "../charts/CountChart";
import MetricLengthChart from "../charts/MetricLengthChart";
import MetricYesNoChart from "../charts/MetricYesNoChart";
import RatingChart from "../charts/RatingChart";
import QuestionPanel from "../util/QuestionPanel";

function GeneralInformation(props) {
    return (
        <div>

            <QuestionPanel question="8">
                <CountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="9">
                <MetricLengthChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="10">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="11">
                <RatingChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="12">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="13">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>

        </div>
    );
}

export default GeneralInformation;
