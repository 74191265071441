import React from "react";
import QuestionPanel from "../util/QuestionPanel";
import MetricYesNoChart from "../charts/MetricYesNoChart";
import CountChart from "../charts/CountChart";
import RatingChart from "../charts/RatingChart";

function SustainableDevelopment(props) {
    return (
        <div>
            <QuestionPanel question="61">
                <CountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="62">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="63">
                <CountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="64">
                <RatingChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="65">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
        </div>
    );
}

export default SustainableDevelopment;
