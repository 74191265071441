const model = {
    activities_reported: { label: "Are the national ocean science activities, including human and technical capacities, published in a specialized report or part of national report since 2010?", numeric: null, units: null },
    activities_reported_info: { label: "Info", numeric: null, units: null },
    category_rating_bluegrowth: { label: "Blue growth", numeric: null, units: null },
    category_rating_climate: { label: "Ocean and climate", numeric: null, units: null },
    category_rating_crust: { label: "Ocean crust and marine geohazards", numeric: null, units: null },
    category_rating_humanhealth: { label: "Human health and wellbeing", numeric: null, units: null },
    category_rating_observation: { label: "Ocean observation and marine data", numeric: null, units: null },
    category_rating_oceanhealth: { label: "Ocean health", numeric: null, units: null },
    category_rating_processes: { label: "Marine ecosystem's functions and processes", numeric: null, units: null },
    category_rating_technology: { label: "Ocean technology and engineering", numeric: null, units: null },
    communication_strategy: { label: "Does your country have a national communication strategy for ocean science, e.g. to ensure knowledge transfer among scienists, to the general public, politicians, policymakers and industries?", numeric: null, units: null },
    communication_strategy_info: { label: "Info", numeric: null, units: null },
    contributes_international_funding: { label: "Does your country contribute financially to international (global, regional) ocean science funding regimes, e.g. Horizon 2020, Belmont Forum, JPI Oceans, WIOMSA, Global Environmental Facility, UN bodies, Asia Pacific Network, Pacific Alliance?", numeric: null, units: null },
    contributes_international_funding_amount: { label: "Amount contributed to ocean science", numeric: null, units: null },
    contributes_international_funding_amount_usd: { label: "Amount contributed to ocean science (USD)", numeric: null, units: null },
    contributes_international_funding_currency: { label: "Currency", numeric: null, units: null },
    contributes_international_funding_info: { label: "Info", numeric: null, units: null },
    contributes_international_funding_programme: { label: "Name of collaboration programme", numeric: null, units: null },
    government_spending_calenderyear: { label: "Calender year", numeric: null, units: null },
    government_spending_central_amount: { label: "Central (or federal) governmental spending for ocean science", numeric: null, units: null },
    government_spending_central_amount_usd: { short: "Central", label: "Central (or federal) governmental spending for ocean science (USD)", numeric: null, units: null },
    government_spending_conversion: { label: "Conversion rate applied (if data are provided in USD)", numeric: null, units: null },
    government_spending_currency: { label: "Currency", numeric: null, units: null },
    government_spending_fiscalyear: { label: "Fiscal year", numeric: null, units: null },
    government_spending_regional_amount: { label: "Regional (or state) and local (or municipal) governmental spending for ocean science", numeric: null, units: null },
    government_spending_regional_amount_usd: { short: "Regional", label: "Regional (or state) and local (or municipal) governmental spending for ocean science (USD)", numeric: null, units: null },
    government_spending_startingmonth: { label: "Fiscal year starting month", numeric: null, units: null },
    government_spending_total_amount: { label: "Total governmental spending for ocean science", numeric: null, units: null },
    government_spending_total_amount_usd: { short: "Total", label: "Total governmental spending for ocean science (USD)", numeric: null, units: null },
    institutions: { label: "Please list research institutions and universities or university faculties/departments specialized in ocean science in your country.", numeric: null, units: null },
    institution_spending_amount: { label: "Ocean science spending", numeric: null, units: null },
    institution_spending_amount_usd: { label: "Ocean science spending (USD)", numeric: null, units: null },
    institution_spending_calenderyear: { label: "Calender year?", numeric: null, units: null },
    institution_spending_conversion: { label: "Conversion rate applied (if data are provided in USD)", numeric: null, units: null },
    institution_spending_currency: { label: "Currency", numeric: null, units: null },
    institution_spending_fiscalyear: { label: "Fiscal year?", numeric: null, units: null },
    institution_spending_institution: { label: "Name of institution/university or university faculty/department", numeric: null, units: null },
    institution_spending_startingmonth: { label: "Fiscal year starting month", numeric: null, units: null },
    ministry_science_technology: { label: "Ministry for science and technology" },
    ministry_research_development: { label: "Ministry for research and development" },
    ministry_education: { label: "Ministry for education - higher education" },
    ministry_fisheries: { label: "Ministry for fisheries" },
    ministry_defense: { label: "Ministry for defense" },
    ministry_environment: { label: "Ministry for environment" },
    ministry_public_works: { label: "Ministry for public works" },
    ministry_planning: { label: "Ministry for planning" },
    ministry_culture: { label: "Ministry for culture" },
    ministry_sea: { label: "Ministry for the sea" },
    ministry_transport: { label: "Ministry of transport" },
    ministry_energy: { label: "Ministry of energy" },
    ministry_health: { label: "Ministry of health" },
    ministry_agriculture: { label: "Ministry of agriculture" },
    ministry_economy: { label: "Ministry of economy" },
    ministry_office_president: { label: "Office of the president" },
    ministry_office_prime_minister: { label: "Prime minister office" },
    ministry_food_security: { label: "Ministry for food security" },
    ministry_others: { label: "Other" },
    ministry_spending_amount: { label: "Ocean science spending", numeric: null, units: null },
    ministry_spending_amount_usd: { label: "Ocean science spending (USD)", numeric: null, units: null },
    ministry_spending_calenderyear: { label: "Calender year?", numeric: null, units: null },
    ministry_spending_conversion: { label: "Conversion rate applied (if data are provided in USD)", numeric: null, units: null },
    ministry_spending_currency: { label: "Currency", numeric: null, units: null },
    ministry_spending_fiscalyear: { label: "Fiscal year?", numeric: null, units: null },
    ministry_spending_ministry: { label: "Name of ministry", numeric: null, units: null },
    ministry_spending_startingmonth: { label: "Fiscal year starting month", numeric: null, units: null },
    national_strategy: { label: "Does your country have a national strategy to achieve the goals of the Agenda 2030 in particular the Sustainable Development Goal 14 and related targets?", numeric: null, units: null },
    national_strategy_info: { label: "Info", numeric: null, units: null },
    personnel_gender_hc_female: {
        label: "Ocean science personnel by gender - Headcount - Total - Female",
        short: "Female",
        numeric: null,
        units: null
    },
    personnel_gender_hc_male: {
        label: "Ocean science personnel by gender - Headcount - Total - Male",
        short: "Male",
        numeric: null,
        units: null
    },
    personnel_gender_hc_researchers_female: {
        label: "Ocean science personnel by gender - Headcount - Researchers - Female",
        short: "Female",
        numeric: null,
        units: null
    },
    personnel_gender_hc_researchers_male: {
        label: "Ocean science personnel by gender - Headcount - Researchers - Male",
        short: "Male",
        numeric: null,
        units: null
    },
    personnel_gender_hc_researchers_total: {
        label: "Ocean science personnel by gender - Headcount - Researchers - Total",
        short: "Total",
        numeric: null,
        units: null
    },
    personnel_gender_hc_total: {
        label: "Ocean science personnel by gender - Headcount - Total",
        short: "Total",
        numeric: null,
        units: null
    },
    personnel_function_hc_notspecified: {
        label: "Ocean science personnel by function - Headcount - Not specified",
        short: "Not specified",
        numeric: null,
        units: null
    },
    personnel_function_hc_other: {
        label: "Ocean science personnel by function - Headcount - Other",
        short: "Other",
        numeric: null,
        units: null
    },
    personnel_function_hc_researchers: {
        label: "Ocean science personnel by function - Headcount - Researchers",
        short: "Researchers",
        numeric: null,
        units: null
    },
    personnel_function_hc_technicians: {
        label: "Ocean science personnel by function - Headcount - Technicians",
        short: "Technicians",
        numeric: null,
        units: null
    },
    personnel_function_hc_total: {
        label: "Ocean science personnel by function - Headcount - Total",
        short: "Total",
        numeric: null,
        units: null
    },
    personnel_function_fte_notspecified: {
        label: "Ocean science personnel by function - FTE - Not specified",
        short: "Not specified",
        numeric: null,
        units: null
    },
    personnel_function_fte_other: {
        label: "Ocean science personnel by function - FTE - Other",
        short: "Other",
        numeric: null,
        units: null
    },
    personnel_function_fte_researchers: {
        label: "Ocean science personnel by function - FTE - Researchers",
        short: "Researchers",
        numeric: null,
        units: null
    },
    personnel_function_fte_technicians: {
        label: "Ocean science personnel by function - FTE - Technicians",
        short: "Technicians",
        numeric: null,
        units: null
    },
    personnel_function_fte_total: {
        label: "Ocean science personnel by function - FTE - Total",
        short: "Total",
        numeric: null,
        units: null
    },
    personnel_age_hc_under25_male: {
        label: "Ocean science personnel by age - Headcount - Age class under 25 years - Male",
        short: "Male"
    },
    personnel_age_hc_under25_female: {
        label: "Ocean science personnel by age - Headcount - Age class under 25 years - Female",
        short: "Female"
    },
    personnel_age_hc_25to34_male: {
        label: "Ocean science personnel by age - Headcount - Age class 25-34 years - Male",
        short: "Male"
    },
    personnel_age_hc_25to34_female: {
        label: "Ocean science personnel by age - Headcount - Age class 25-34 years - Female",
        short: "Female"
    },
    personnel_age_hc_35to44_male: {
        label: "Ocean science personnel by age - Headcount - Age class 35-44 years - Male",
        short: "Male"
    },
    personnel_age_hc_35to44_female: {
        label: "Ocean science personnel by age - Headcount - Age class 35-44 years - Female",
        short: "Female"
    },
    personnel_age_hc_45to54_male: {
        label: "Ocean science personnel by age - Headcount - Age class 45-54 years - Male",
        short: "Male"
    },
    personnel_age_hc_45to54_female: {
        label: "Ocean science personnel by age - Headcount - Age class 45-54 years - Female",
        short: "Female"
    },
    personnel_age_hc_55to64_male: {
        label: "Ocean science personnel by age - Headcount - Age class 55-64 years - Male",
        short: "Male"
    },
    personnel_age_hc_55to64_female: {
        label: "Ocean science personnel by age - Headcount - Age class 55-64 years - Female",
        short: "Female"
    },
    personnel_age_hc_over64_male: {
        label: "Ocean science personnel by age - Headcount - Age class 65 years and more - Male",
        short: "Male"
    },
    personnel_age_hc_over64_female: {
        label: "Ocean science personnel by age - Headcount - Age class 65 years and more - Female",
        short: "Female"
    },
    personnel_qualification_hc_doctoral_male: {
        label: "Ocean science personnel by qualification - Headcount - Doctoral or equivalent (ISCED 8) - Male",
        short: "Male"
    },
    personnel_qualification_hc_doctoral_female: {
        label: "Ocean science personnel by qualification - Headcount - Doctoral or equivalent (ISCED 8) - Female",
        short: "Female"
    },
    personnel_qualification_hc_master_male: {
        label: "Ocean science personnel by qualification - Headcount - Master's or equivalent (ISCED 7) - Male",
        short: "Male"
    },
    personnel_qualification_hc_master_female: {
        label: "Ocean science personnel by qualification - Headcount - Master's or equivalent (ISCED 7) - Female",
        short: "Female"
    },
    personnel_qualification_hc_bachelor_male: {
        label: "Ocean science personnel by qualification - Headcount - Bachelor's or equivalent (ISCED 6) - Male",
        short: "Male"
    },
    personnel_qualification_hc_bachelor_female: {
        label: "Ocean science personnel by qualification - Headcount - Bachelor's or equivalent (ISCED 6) - Female",
        short: "Female"
    },
    personnel_qualification_hc_shortcycle_male: {
        label: "Ocean science personnel by qualification - Headcount - Short-cycle tertiary (ISCED 5) - Male",
        short: "Male"
    },
    personnel_qualification_hc_shortcycle_female: {
        label: "Ocean science personnel by qualification - Headcount - Short-cycle tertiary (ISCED 5) - Female",
        short: "Female"
    },
    personnel_qualification_hc_other_male: {
        label: "Ocean science personnel by qualification - Headcount - All other qualifications (ISCED 4 and below) - Male",
        short: "Male"
    },
    personnel_qualification_hc_other_female: {
        label: "Ocean science personnel by qualification - Headcount - All other qualifications (ISCED 4 and below) - Female",
        short: "Female"
    },
    personnel_qualification_hc_notspecified_male: {
        label: "Ocean science personnel by qualification - Headcount - Not specified - Male",
        short: "Male"
    },
    personnel_qualification_hc_notspecified_female: {
        label: "Ocean science personnel by qualification - Headcount - Not specified - Female",
        short: "Female"
    },
    vessels_under10m: {
        label: "Number of vessels - <10 m",
        short : "<10 m"
    },
    vessels_coastal: {
        label: "Number of vessels - Local coastal ≥10 m <35 m",
        short : "Local coastal ≥10 m <35 m"
    },
    vessels_regional: {
        label: "Number of vessels - Regional ≥35 m <55 m",
        short : "Regional ≥35 m <55 m"
    },
    vessels_international: {
        label: "Number of vessels - International ≥55 m <65 m",
        short : "International ≥55 m <65 m"
    },
    vessels_global: {
        label: "Number of vessels - Global ≥65 m",
        short : "Global ≥65 m"
    },
    vessels_over_55m: { label: "Vessels over 55 m" },
    technology_human_operated_vessel_submersible: { label: "Human Operated Vessel (Submersible)" },
    technology_surface_unmanned_vessel: { label: "Surface Unmanned Vessel (SUV)" },
    technology_remotely_operated_vessel: { label: "Remotely Operated Vessel (ROV)" },
    technology_autonomous_underwater_vessel: { label: "Autonomous Underwater Vessel (AUV)" },
    technology_underwater_glider: { label: "Underwater Glider" },
    technology_wave_glider: { label: "Wave Glider" },
    technology_marine_drone: { label: "Marine Drone" },
    technology_mooring_buoy: { label: "Mooring Buoy" },
    technology_underwater_cable_system: { label: "Underwater Cable System" },
    technology_doppler_rader_system: { label: "Doppler Rader System" },
    technology_radiosonde_launcher: { label: "Radiosonde Launcher" },
    technology_scientific_fish_finder: { label: "Scientific Fish Finder" },
    technology_single_channel_hydrophone_array: { label: "Single Channel Hydrophone Array (Streamer)" },
    technology_multichannel_hydrophone_array: { label: "Multichannel Hydrophone Array (Streamer)" },
    technology_multicable_multichannel_hydrophone_array: { label: "Multicable Multichannel Hydrophone Array" },
    technology_air_gun_water_gun: { label: "Air Gun/ Water Gun" },
    technology_subbottom_profiler: { label: "Sub-bottom profiler" },
    technology_multi_narrow_beam_bottom_profiler: { label: "Multi narrow beam bottom profiler" },
    technology_remotely_operated_drilling_equipment: { label: "Remotely operated Drilling equipment" },
    technology_remotely_operated_seafloor_sampler: { label: "Remotely operated seafloor sampler" },
    technology_multinet_plankton_sampler: { label: "Multinet plankton sampler" },
    technology_deepsea_camera_system: { label: "Deep-Sea Camera System" },
    technology_stereoscopic_deepsea_camera_system: { label: "Stereoscopic Deep-sea Camera System" },
    technology_equipment_for_bathymetric_studies: { label: "Equipment for bathymetric studies" },
    technology_auto_analyzer_chemical_analyses: { label: "Auto Analyzer for chemical analyses" },
    technology_xray_tomography: { label: "X-ray tomography" },
    technology_mass_spectrometer: { label: "Mass Spectrometer" },
    technology_fitc: { label: "FITC" },
    technology_radio_isotope_laboratory: { label: "Radio Isotope laboratory" },
    technology_dna_sequencer: { label: "DNA sequencer" },
    technology_deep_freezer: { label: "Deep Freezer" },
    technology_liquid_nitrogen_generator: { label: "Liquid Nitrogen Generator" },
    technology_other_onboard_lab_equipment: { label: "Other Laboratory Equipment on Board" },
    technology_supercomputer: { label: "Supercomputer" },
    technology_satellite_communication_antenna: { label: "Satellite Communication Antenna" },
    has_nodc: { label: "Has NODC", short: "NODC" },
    has_adu: { label: "Has ADU", short: "ADU" },
    has_obis_node: { label: "Has an OBIS node", short: "OBIS node" },
    has_marine_library: { label: "Has a marine library", short: "marine library" },
    has_nodc_info: { label: "Has NODC (info)", short: "NODC" },
    has_adu_info: { label: "Has ADU (info)", short: "ADU" },
    has_obis_node_info: { label: "Has an OBIS node (info)", short: "OBIS node" },
    has_marine_library_info: { label: "Has a marine library (info)", short: "marine library" },
    collaboration_national: { label: "National" },
    collaboration_regional: { label: "Regional" },
    collaboration_international: { label: "International" },
    collaboration_ioc_oceanscience: { label: "Ocean Science" },
    collaboration_ioc_goos: { label: "Ocean Observations and Services (GOOS)" },
    collaboration_ioc_tsunami: { label: "Tsunami Unit" },
    collaboration_ioc_marinepolicy: { label: "Marine Policy Science" },
    collaboration_ioc_dontknow: { label: "I do not know" },
    observational_data_biological: { label: "Biological data" },
    observational_data_physical: { label: "Physical data" },
    observational_data_geo: { label: "Geological and geophysical" },
    observational_data_chemical: { label: "Chemical" },
    observational_data_pollutant: { label: "Pollutant" },
    observational_data_fisheries: { label: "Fisheries data" },
    observational_data_socioeconomic: { label: "Socio-economic" },
    observational_data_realtime: { label: "Real-time data" },
    observational_data_other: { label: "Other data types" },
    data_products_metadata_access: { label: "Online access to metadata" },
    data_products_data_access: { label: "Online access to data" },
    data_products_library_access: { label: "Online access to library catalogue" },
    data_products_documents_access: { label: "Online access to e-publications" },
    data_products_published_data: { label: "Published ocean data" },
    data_products_communication: { label: "Online access to communication products" },
    data_products_gis: { label: "GIS products" },
    data_products_portals: { label: "Portals" },
    data_products_model_data: { label: "Numerical model data" },
    data_products_cdrom: { label: "CD-ROM products" },
    data_products_other: { label: "Other data/information products" },
    data_services_archival: { label: "Metadata and data archival" },
    data_services_personal_repository: { label: "Personal data repository" },
    data_services_cloud_computing: { label: "Cloud computing facilities" },
    data_services_vre: { label: "Virtual research environment" },
    data_services_webservices: { label: "Web services" },
    data_services_pids: { label: "Provision of PIDs" },
    data_services_analysis: { label: "Data analysis tools" },
    data_services_visualization: { label: "Data visualisation tools" },
    data_services_qc: { label: "Data quality control tools" },
    data_services_communication: { label: "Communication tools" },
    data_services_special: { label: "Special tools" },
    data_services_standards: { label: "Access to documented methods, standards and guidelines" },
    data_services_other: { label: "Other services" },
    data_policy_institutional: { label: "Institutional" },
    data_policy_national: { label: "National" },
    data_policy_international: { label: "International" },
    restricts_access_no: { label: "We do not restrict at all" },
    restricts_access_types: { label: "We restrict access to certain data types" },
    restricts_access_areas: { label: "We restrict access to data collected in certain geographic areas" },
    restricts_access_embargo: { label: "We restrict access during a certain period of time (embargo)" },
    restricts_access_other: { label: "Any other restrictions" },
    private_spending_business_amount: { label: "Business enterprise sector spending for ocean science", numeric: null, units: null },
    private_spending_business_amount_usd: { short: "Business", label: "Business enterprise sector spending for ocean science (USD)", numeric: null, units: null },
    private_spending_calenderyear: { label: "Calender year", numeric: null, units: null },
    private_spending_currency: { label: "Currency", numeric: null, units: null },
    private_spending_fiscalyear: { label: "Fiscal year", numeric: null, units: null },
    private_spending_nonprofit_amount: { label: "Private non-profit sector spending for ocean science", numeric: null, units: null },
    private_spending_nonprofit_amount_usd: { short: "Non-profit", label: "Private non-profit sector spending for ocean science (USD)", numeric: null, units: null },
    private_spending_startingmonth: { label: "Fiscal year starting month", numeric: null, units: null },
    receives_international_funding: { label: "Do the scientists in your country receive financial support from international (global, regional) ocean science funding regimes, e.g. Horizon 2020, Belmont Forum, JPI Oceans, WIOMSA, Global Environmental Facility, UN bodies, Asia Pacific Network, Pacific Alliance?", numeric: null, units: null },
    receives_international_funding_amount: { label: "Amount received for ocean science", numeric: null, units: null },
    receives_international_funding_amount_usd: { label: "Amount received for ocean science (USD)", numeric: null, units: null },
    receives_international_funding_conversion: { label: "Conversion rate applied (if data are provided in US$)", numeric: null, units: null },
    receives_international_funding_currency: { label: "Currency", numeric: null, units: null },
    receives_international_funding_info: { label: "Info", numeric: null, units: null },
    receives_international_funding_programme: { label: "Name of collaboration programme", numeric: null, units: null },
    capacity_needs_rating_training: { label: "Academic (higher) training, basic training in ocean science" },
    capacity_needs_rating_specialized_training: { label: "Specialized technical (advanced) training in certain topics, training, short term courses" },
    capacity_needs_rating_human: { label: "Human capacity, increase the number of ocean science personnel" },
    capacity_needs_rating_sampling_equipment: { label: "Sampling and analysis equipment" },
    capacity_needs_rating_observation_equipment: { label: "Observation facilities and equipment" },
    capacity_needs_rating_lab_observations: { label: "Equipment for in situ and laboratory observations, analysis and experimentation" },
    capacity_needs_rating_computer: { label: "Computer and computer software, including models and modeling techniques" },
    capacity_needs_rating_conferences: { label: "Opportunities to share our experience at conferences" },
    capacity_needs_rating_networking: { label: "Networking (community building) with colleagues" },
    capacity_needs_rating_funding: { label: "Funding" },
    capacity_needs_rating_internet: { label: "Internet connectivity" },
    training_needs_rating_research: { label: "Technical training for ocean science related to research activities" },
    training_needs_rating_observation: { label: "Technical training for ocean science related to ocean observation" },
    training_needs_rating_datamanagement: { label: "Technical training for ocean science data management" },
    training_needs_rating_sustainablemanagement: { label: "Technical training for ocean science related to sustainable management" },
    training_needs_rating_communication: { label: "Technical training for ocean science communication" },
    data_users_own_institution: { label: "Only users in my own institution" },
    data_users_national_researchers: { label: "National researchers in my own country" },
    data_users_researchers: { label: "Researchers in any country" },
    data_users_policy_makers_own_ministry: { label: "Policy makers of my own ministry" },
    data_users_policy_makers_other_ministry: { label: "Policy makers in other ministries of my country" },
    data_users_policy_makers: { label: "Policy makers in any country (e.g. through UN commitments)" },
    data_users_military: { label: "Military" },
    data_users_civil_protection: { label: "Civil protection" },
    data_users_private_sector: { label: "Private sector (e.g. fisheries, hotels, industry,...)" },
    data_users_school_children: { label: "School children" },
    data_users_undergraduate: { label: "Undergraduate students" },
    data_users_general_public: { label: "General public" },
    data_users_press: { label: "Print/TV press" },
    data_users_social_media: { label: "Social media" },
    data_users_service_providers: { label: "Service providers, e.g. weather forecast organizations/institutions" },
    data_users_other: { label: "Other clients and end users" },
    outside_experts_guest_positions: { label: "Guest positions" },
    outside_experts_exchange_programmes: { label: "Exchange programmes" },
    outside_experts_board_memberships: { label: "Board memberships" },
    outside_experts_advisory_capacity: { label: "Advisory capacity" },
    outside_experts_others: { label: "Others" },
    outside_experts_none: { label: "There are none" },
    agenda_2030_national_strategy: { short: "Yes", label: "Does your country have a national strategy to achieve the goals of the Agenda 2030?" },
    agenda_2030_national_strategy_sdg_14: { short: "Yes, specific SDG 14", label: "Does your country have a national strategy to achieve the goals of the Agenda 2030 - in particular the Sustainable Development Goal 14 and related targets?" },
    agenda_2030_national_strategy_info: { label: "National strategy - Info" },
    agenda_2030_national_strategy_sdg_14_info: { label: "National strategy - SDG 14 - Info" },
    reporting_sdg_14_1: { label: "SDG 14.1" },
    reporting_sdg_14_2: { label: "SDG 14.2" },
    reporting_sdg_14_3: { label: "SDG 14.3" },
    reporting_sdg_14_4: { label: "SDG 14.4" },
    reporting_sdg_14_5: { label: "SDG 14.5" },
    reporting_sdg_14_6: { label: "SDG 14.6" },
    reporting_sdg_14_7: { label: "SDG 14.7" },
    reporting_sdg_14_a: { label: "SDG 14.a" },
    reporting_sdg_14_b: { label: "SDG 14.b" },
    reporting_sdg_14_c: { label: "SDG 14.c" },
    ocean_services_rating_food_provision: { label: "Food provision" },
    ocean_services_rating_water_provision: { label: "Water storage and provision" },
    ocean_services_rating_biotic_materials: { label: "Biotic materials and biofuels" },
    ocean_services_rating_water_purification: { label: "Water purification" },
    ocean_services_rating_air_quality: { label: "Air quality regulation" },
    ocean_services_rating_coastal_protection: { label: "Coastal protection" },
    ocean_services_rating_climate_regulation: { label: "Climate regulation" },
    ocean_services_rating_weather_regulation: { label: "Weather regulation" },
    ocean_services_rating_ocean_nourishment: { label: "Ocean nourishment" },
    ocean_services_rating_life_cycle_maintenance: { label: "Life cycle maintenance" },
    ocean_services_rating_biological_regulation: { label: "Biological regulation" },
    ocean_services_rating_symbolic_values: { label: "Symbolic and aesthetic values" },
    ocean_services_rating_recreation: { label: "Recreation and tourism" },
    ocean_services_rating_cognitive_effects: { label: "Cognitive effects" },
    participates_ships_of_opportunity: { label: "Do ocean science institutions, universities and/or faculties/departments of universities in your country involved in ocean science participate in efforts related to ships of opportunity/voluntary observing ships?" },
    owns_maintains_vessels_partly_used: { label: "Does your country own and maintain vessels partly used for ocean science?" },
    owns_maintains_vessels: { label: "Does your country own and maintain research vessels?" },
    vessels_type: { label: "Vessel type" },
    vessels_total: { label: "Number of vessels - total" },
    vessel_days_eez: { label: "Days at sea - Exclusive Economic Zone" },
    vessel_days_type: { label: "Vessel type" },
    vessel_days_total: { label: "Days at sea - total" },
    vessel_days_highseas: { label: "Days at sea - High Seas" },
    vessel_days_territorial: { label: "Days at sea - Territorial waters" },
    access_literature_information: { label: "How would you rank your countries access to national and international scientific literature and information (e.g. peer reviewed journals, data bases)?" },
    data_policy_national_info: { label: "National (info)" },
    data_policy_institutional_info: { label: "Institutional (info)" },
    data_policy_international_info: { label: "International (info)" },
    data_contributing_international_systems: { label: "Are data and information from your country’s data centre(s) contributing to international systems (meaning that you actively send data, or make data and metadata available, to e.g. the ICSU World Data System, GDACs, WMO Global Telecommunication System (GTS) or other such international systems?" },
    has_observation_programmes: { label: "Does your country have ocean observation programme(s)/activity(ies)?" },
    has_observation_programmes_info: { label: "Info" },
    satellite_observations: { label: "Does your country’s ocean observation include satellite observations?" },
    satellite_observations_info: { label: "Info" },
    efforts_keep_graduates: { label: "Does your country have special national efforts and mechanisms to absorb and keep graduates in ocean science related positions and activities (e.g. PhD programmes, young scientist funding resources, exchange programmes, early career support)?" },
    efforts_keep_graduates_info: { label: "Info" },
    participates_bilateral_support: { label: "Does your country take part in bilateral support / training to increase scientific in particular ocean science related capacities, e.g. Fullbright scholarships, EEA and Norway Grants (EØS-midlene), Fish for Development and civil society 2018-2022?"},
    participates_bilateral_support_info: { label: "Info" },
    efforts_support_female_graduates: { label: "Does your country have special national efforts and mechanisms to support female graduates and scientists in ocean science related positions and activities?" },
    efforts_support_female_graduates_info: { label: "Info" },
    participates_international_support: { label: "Does your country take part in regional/international support/training programmes, such as POGO, SCOR, OT (Ocean Teacher), Regional Network of Training and Research Centers (RTRC), ICES, PICES, IOC to increase scientific in particular ocean science related capacities?" },
    participates_international_support_info: { label: "Info" },
    has_sdg_14_focal_point: { label: "Does your country have a national focal point for the Sustainable Development Goal 14 (https://sustainabledevelopment.un.org/sdg14) and related targets?" },
    has_sdg_14_focal_point_info: { label: "Info" },
    access_other_vessels: { label: "Do ocean science researchers have access to research vessels, which are not encompassed by questions 30-32?" },
    journals_not_in_wos: { label: "How many peer reviewed journals in national languages not indexed in Web of Science are published in your country?" },
    complies_fair: { label: "Do your country’s data centre(s) comply with the FAIR data management criteria?" },
    collaboration_info: { label: "Info" },
    blue_economy_strategy: { label: "Does your country have specific activities contributing to economies related to the sustainable use of ocean resources and/or developed a blue/ocean economy strategy?" },
    blue_economy_strategy_info: { label: "Info" },
    ministry_others_specify: { label: "Other - Info" },
    applies_ioc_policy: { label: "Do(es) your country’s data centre(s) apply the IOC Oceanographic Data Exchange Policy adopted as Resolution IOC-XXII-6?" },
    personnel_permanent: { label: "Please provide the percentage of permanent employees of your country’s current ocean science personal (overall)." },
    personnel_qualification_hc_total: { short: "Total", label: "Ocean science personnel by qualification - Headcount - Total" },
    personnel_qualification_hc_other_total: { short: "Total", label: "Ocean science personnel by qualification - Headcount - All other qualifications (ISCED 4 and below) - Total" },
    personnel_qualification_hc_master_total: { short: "Total", label: "Ocean science personnel by qualification - Headcount - Master's or equivalent (ISCED 7) - Total" },
    personnel_qualification_hc_bachelor_total: { short: "Total", label: "Ocean science personnel by qualification - Headcount - Bachelor's or equivalent (ISCED 6) - Total" },
    personnel_qualification_hc_doctoral_total: { short: "Total", label: "Ocean science personnel by qualification - Headcount - Doctoral or equivalent (ISCED 8) - Total" },
    personnel_qualification_hc_shortcycle_total: { short: "Total", label: "Ocean science personnel by qualification - Headcount - Short-cycle tertiary (ISCED 5) - Total" },
    personnel_qualification_hc_notspecified_total: { short: "Total", label: "Ocean science personnel by qualification - Headcount - Not specified - Total" },
    personnel_age_hc_total: { short: "Total", label: "Ocean science personnel by age - Headcount - Total" },
    personnel_age_hc_25to34_total: { short: "Total", label: "Ocean science personnel by age - Headcount - Age class 25-34 years - Total" },
    personnel_age_hc_35to44_total: { short: "Total", label: "Ocean science personnel by age - Headcount - Age class 35-44 years - Total" },
    personnel_age_hc_45to54_total: { short: "Total", label: "Ocean science personnel by age - Headcount - Age class 45-54 years - Total" },
    personnel_age_hc_55to64_total: { short: "Total", label: "Ocean science personnel by age - Headcount - Age class 55-64 years - Total" },
    personnel_age_hc_over64_total: { short: "Total", label: "Ocean science personnel by age - Headcount - Age class 65 years and more - Total" },
    personnel_age_hc_under25_total: { short: "Total", label: "Ocean science personnel by age - Headcount - Age class under 25 years - Total" }
};

export default model;