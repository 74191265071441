import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();

ReactGA.initialize('G-T38BT4LR66');
ReactGA.pageview(window.location.pathname + window.location.search);