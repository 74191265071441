import React, { useEffect, useRef, useState } from "react";
import Plotly from "plotly.js";
import questions from "../../util/questions";
import transform from "../../util/transform";
import doFetch from "../../util/util";

/**
 * Bar chart with country on the x axis and the array size of a specific metric on the y axis.
 */
export default function MetricLengthChart({ region, config, question }) {

    config = question ? questions[question] : config;
    const containerRef = useRef(null);
    const initialConfig = useRef(config);
    const [hasData, setHasData] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const config = initialConfig.current;
            console.log("Fetching data for question " + config.question);
            const res = await doFetch("/api/metrics?question=" + config.question + "&region=" + region);
            const data = await res.json();

            if (data && data.total) {
                setHasData(true);
                const {traces, tickvals, ticktext} = transform.transformMetricLengthChart(data, config);
                const layout = {
                    barmode: config.barmode,
                    xaxis: {
                        dtick: 1
                    },
                    margin: {
                        l: 50,
                        r: 50,
                        b: 150,
                        t: 50,
                        pad: 4
                    }
                };
                if (tickvals) {
                    layout.xaxis.tickmode = "array";
                    layout.xaxis.tickvals = tickvals;
                    layout.xaxis.ticktext = ticktext;
                }
                if (containerRef.current) Plotly.newPlot(containerRef.current, traces, layout);
            } else {
                if (containerRef.current) Plotly.purge(containerRef.current);
                setHasData(false);
            }

        };
        fetchData();
    }, [region]);

    return <div className="barchart" ref={containerRef}>
        { !hasData && <div>No data.</div> }
    </div>;

}