import React from "react";
import RatingChart from "../charts/RatingChart";
import QuestionPanel from "../util/QuestionPanel";
import ValueCountChart from "../charts/ValueCountChart";
import MetricYesNoChart from "../charts/MetricYesNoChart";
import CountChart from "../charts/CountChart";

function CapacityDevelopment(props) {
    return (
        <div>
            <QuestionPanel question="52">
                <RatingChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="53">
                <RatingChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="54">
                <ValueCountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="55">
                <ValueCountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="56">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="57">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="58">
                <CountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="59">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="60">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
        </div>
    );
}

export default CapacityDevelopment;
