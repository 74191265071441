import React, { useEffect, useRef, useState } from "react";
import Plotly from "plotly.js";
import Select from "../util/Select";
import transform from "../../util/transform";
import questions from "../../util/questions";

/**
 * Bar chart with country on the x axis and metrics stacked on the y axis.
 */
export default function BarChart({ region, config, year, question }) {

    config = question ? questions[question] : config;
    const containerRef = useRef(null);
    const [subset, setSubset] = useState("default");
    const [hasData, setHasData] = useState(false);
    const initialConfig = useRef(config);

    useEffect(() => {
        const fetchData = async () => {
            const config = initialConfig.current;
            console.log("Fetching data for question " + config.question);
            const res = await fetch("/api/metrics?question=" + config.question + "&region=" + region);
            const data = await res.json();
            if (data && data.total) {
                setHasData(true);
                const {traces, tickvals, ticktext} = transform.transformBarChart(data, config, subset, year);
                const layout = {
                    barmode: config.barmode,
                    xaxis: {
                        dtick: 1,
                        automargin: true
                    },
                    margin: {
                        l: 50,
                        r: 50,
                        b: 150,
                        t: 50,
                        pad: 4
                    }
                };
                if (tickvals) {
                    layout.xaxis.tickmode = "array";
                    layout.xaxis.tickvals = tickvals;
                    layout.xaxis.ticktext = ticktext;
                }
                if (containerRef.current) Plotly.newPlot(containerRef.current, traces, layout);
            } else {
                if (containerRef.current) Plotly.purge(containerRef.current);
                setHasData(false);
            }
        };
        fetchData();
    }, [region, year, subset]);

    const options = Object.keys(config.subsets).map(key => { return { id: key, name: config.subsets[key].label }});

    function handleSubsetChange(e) {
        setSubset(e.target.value);
    }

    return <div>
        { options.length > 1 && hasData &&
            <div className="mt-4 ml-4 flex">
                <Select options={options} value={subset} onChange={handleSubsetChange} />
            </div>
        }
        <div className="barchart" ref={containerRef}>
            { !hasData && <div>No data.</div> }
        </div>
    </div>;

}