import React from "react";
import BarChart from "../charts/BarChart";
import ValueCountChart from "../charts/ValueCountChart";
import MetricYesNoChart from "../charts/MetricYesNoChart";
import MetricLengthChart from "../charts/MetricLengthChart";
import SumBarChart from "../charts/SumBarChart";
import CountChart from "../charts/CountChart";
import QuestionPanel from "../util/QuestionPanel";

function ResearchCapacity(props) {
    return (
        <div>
            <QuestionPanel question="22">
                <BarChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="23">
                <BarChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="24">
                <BarChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="25">
                <BarChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="26">
                <BarChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="27">
                <ValueCountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="28">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="29">
                <ValueCountChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="30">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="31">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="32">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="33">
                <MetricYesNoChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="34">
                <BarChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="35">
                <MetricLengthChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="36">
                <SumBarChart region={props.region} year={props.year} />
            </QuestionPanel>
            <QuestionPanel question="37">
                <CountChart region={props.region} year={props.year} />
            </QuestionPanel>

        </div>
    );
}

export default ResearchCapacity;
