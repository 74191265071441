import React from "react";
import Downloader from "../util/Downloader";

export default function DataAccess() {
    return <div>
        <h1 className="mt-5 mb-4 font-bold">Data access</h1>
        <p>The Global Ocean Science Report dataset can be downloaded as a CSV or JSON file.</p>
        <Downloader className="mt-6" />
        <Downloader className="mt-6 ml-3" json="true" />

        <h3>Additional data sources</h3>

        <p>Coastline data: The CIA World Factbook, available at <a href="https://www.cia.gov/library/publications/resources/the-world-factbook/" target="_blank" rel="noopener noreferrer">https://www.cia.gov/library/publications/resources/the-world-factbook/</a>. Accessed on 17/12/2019.</p>
        <p>Population data: The World Bank Data Catalog, available at <a href="https://datacatalog.worldbank.org/" target="_blank" rel="noopener noreferrer">https://datacatalog.worldbank.org/</a> (code SP.POP.TOTL). Total population is based on the de facto definition of population, which counts all residents regardless of legal status or citizenship. The values shown are midyear estimates.</p>
        <p>GDP data: World Development Indicators. Accessed on 20/12/2019.</p>
        <p>GERD data: UIS. Accessed in November 2019.</p>
        <p>Exchange rates: The World Bank Data Catalog, available at <a href="https://datacatalog.worldbank.org/" target="_blank" rel="noopener noreferrer">https://datacatalog.worldbank.org/</a> (code DPANUSSPB).</p>

    </div>
}