import React from "react";
import { NavLink } from "react-router-dom";

export default function MainNavigation() {
    return <div>
        <NavLink to="/theme" className="ml-3 text-black hover:text-primary" activeClassName="font-bold text-primary">themes</NavLink>
        <NavLink to="/report" className="ml-3 text-black hover:text-primary" activeClassName="font-bold text-primary">the report</NavLink>
        <NavLink to="/methodology" className="ml-3 text-black hover:text-primary" activeClassName="font-bold text-primary">methodology</NavLink>
        <NavLink to="/data" className="ml-3 text-black hover:text-primary" activeClassName="font-bold text-primary">data access</NavLink>
    </div>
}