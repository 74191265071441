import React from "react";
import {Link} from "react-router-dom";

export default function Home() {
    return <div className="text-center">
        <h1 className="mt-10 mb-10 font-bold">Charting Capacity for Ocean Sustainability</h1>
        <p className="mb-5">The world ocean is a life-supporting system for humanity, yet it remains largely unknown. Based on data collected from around the world, the Global Ocean Science Report 2020 (GOSR2020) offers a global record of how, where and by whom ocean science is conducted. By analysing the workforce, infrastructures, equipment, funding, investments, publications, data flow and exchange policies, as well as national strategies, the GOSR monitors our capacity to understand the ocean and seize new opportunities. In its second edition, the GOSR2020 addresses four additional topics: contribution of ocean science to sustainable development; blue patent applications; extended gender analysis; and capacity development in ocean science.</p>
        <p className="mb-8">The GOSR2020 is a resource for policymakers, academics and other stakeholders seeking to assess progress towards the sustainable development goals of the UN 2030 Agenda, in particular SDG target 14.a on scientific knowledge, research capacity and transfer of marine technology. The GOSR provides the information for the indicator for target 14.a as the proportion of total research budget allocated to research in the field of ocean science. GOSR2020 not only provides consistent reference information at the start of UN Decade for Ocean Science for Sustainable Development 2021–2030, it evolves as a living product. The global community is given the online facility to submit and update data on the GOSR portal and consult data to regularly assess progress on the efficiency and impact of policies to develop ocean science capacity.</p>

        <div className="mb-8 flex flex-col items-center">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/bGfo9P7fY2M" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
        </div>

        <p><Link to="/theme" className="inline-block appearance-none rounded-none py-3 px-4 mr-2 mb-2 outline-none leading-tight hover:bg-secondary-lighter border border-primary bg-primary text-white cursor-pointer">Explore themes</Link></p>
    </div>
}