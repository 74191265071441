import React from "react";

export default function Report() {
    return <div>
        <h1 className="mt-5 mb-4 font-bold">The report</h1>
        <p>Download the full report:</p>
        <ul>
            <li><a href="https://unesdoc.unesco.org/ark:/48223/pf0000375147" target="_blank" rel="noopener noreferrer">Full Report (PDF in English)</a></li>
        </ul>
        <p>Download the executive summary:</p>
        <ul>
            <li><a href="https://unesdoc.unesco.org/ark:/48223/pf0000375148" target="_blank" rel="noopener noreferrer">Executive Summary (PDF in English)</a></li>
            <li><a href="https://unesdoc.unesco.org/ark:/48223/pf0000375148_fre" target="_blank" rel="noopener noreferrer">Executive Summary (PDF in French)</a></li>
            <li><a href="https://unesdoc.unesco.org/ark:/48223/pf0000375148_spa" target="_blank" rel="noopener noreferrer">Executive Summary (PDF in Spanish)</a></li>
            <li><a href="https://unesdoc.unesco.org/ark:/48223/pf0000375148_rus" target="_blank" rel="noopener noreferrer">Executive Summary (PDF in Russian)</a></li>
            <li><a href="https://unesdoc.unesco.org/ark:/48223/pf0000375148_chi" target="_blank" rel="noopener noreferrer">Executive Summary (PDF in Chinese)</a></li>
            <li><a href="https://unesdoc.unesco.org/ark:/48223/pf0000375148_ara" target="_blank" rel="noopener noreferrer">Executive Summary (PDF in Arabic)</a></li>
            <li><a href="https://unesdoc.unesco.org/ark:/48223/pf0000375148_jpn" target="_blank" rel="noopener noreferrer">Executive Summary (PDF in Japanese)</a></li>
        </ul>
        <p>For more information about the first edition of the GOSR please click <a href="https://en.unesco.org/gosr2017" target="_blank">here</a>.</p>
    </div>
}
