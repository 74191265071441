import colorbrewer from "colorbrewer";
import model from "./model";

const truncate = function(values) {
    const truncated = values.map(x => {
        if (x.length > 20) {
            return x.substring(0, 18) + "...";
        } else {
            return x;
        }
    });
    return truncated;
};

/**
 * Check if the fallback key needs to be used for this metric instead of the default keys.
 * The fallback key is only used if:
 * - the fallback keys is present and not zero
 * - not all default keys are present, or all are present but all zero
 */
const useFallback = function(metric, keys, fallbackKey) {
    if (!(fallbackKey in metric) || metric[fallbackKey] === 0) {
        return false;
    }
    if (!(keys.every(key => key in metric))) {
        return true;
    }
    if (keys.every(key => metric[key] === 0)) {
        return true;
    }
    return false;
};

const transformBarChart = function(data, config, subset, year) {
    const colors = colorbrewer.Blues[Math.max(config.subsets[subset].series.length, 3)];

    // get default and fallback keys

    const keys = config.subsets[subset].series.map(s => s.key);
    let fallbackKey;
    if (config.subsets[subset].fallbackSeries) {
        fallbackKey = config.subsets[subset].fallbackSeries[0].key;
    }

    // create object for each series

    const series = {};
    config.subsets[subset].series.forEach((s, i) => {
        series[s.key] = {
            map: {},
            type: "bar",
            name: model[s.key] ? model[s.key].short : "NOT FOUND",
            marker: { color: colors[i] }
        };
    });

    if (fallbackKey) {
        series[fallbackKey] = {
            map: {},
            type: "bar",
            name: model[fallbackKey] ? model[fallbackKey].short : "NOT FOUND",
            marker: { color: colors[config.subsets[subset].series.length] }
        };
    }

    // process metrics and add to series

    data.results.forEach(answer => {
        answer.metrics.forEach(metric => {
            if (metric.year === parseInt(year)) {

                let doKeys = keys;
                if (useFallback(metric, keys, fallbackKey)) {
                    doKeys = [fallbackKey];
                }

                doKeys.forEach(k => {
                    if (answer.name in series[k].map) {
                        if (k in metric) series[k].map[answer.name] += metric[k];
                    } else {
                        if (k in metric) series[k].map[answer.name] = metric[k];
                    }
                });
            }
        });
    });

    // create traces for plotly

    const tickSet = new Set();
    const traces = Object.keys(series).map(key => {
        series[key].x = Object.keys(series[key].map);
        series[key].y = Object.values(series[key].map);
        series[key].x.forEach(item => tickSet.add(item));
        return series[key];
    });
    const ticks = [...tickSet];
    return {
        traces: traces,
        tickvals: ticks,
        ticktext: truncate(ticks)
    };
};

const transformSumBarChart = function(data, config, year) {
    const results = {};
    data.results.forEach(answer => {
        answer.metrics.forEach(metric => {
            if (metric.year === parseInt(year)) {
                if (!(answer.name in results)) {
                    results[answer.name] = 0;
                }
                if (config.metric in metric) {
                    results[answer.name] = results[answer.name] + metric[config.metric];
                }
            }
        });
    });
    const series = {
        x: [],
        y: [],
        type: "bar"
    };
    Object.entries(results).forEach(([key, value]) => {
        series.x.push(key);
        series.y.push(value);
    });
    const ticks = series.x;
    return {
        traces: [ series ],
        tickvals: ticks,
        ticktext: truncate(ticks)
    };
};

const transformRatingChart = function(data) {
    let transformed = aggregateByMetricValue(data.results);
    const colors = colorbrewer.Blues[6];
    const series = {};
    ["0", "1", "2", "3", "4", "5"].forEach(s => {
        series[s] = {
            x: [],
            y: [],
            type: "bar",
            name: s,
            marker: { color: colors[parseInt(s)] }
        }
    });
    const tickSet = new Set();
    Object.keys(transformed).forEach(key => {
        tickSet.add(model[key].label);
        ["0", "1", "2", "3", "4", "5"].forEach(s => {
            series[s].x.push(model[key].label);
            series[s].y.push(s in transformed[key] ? transformed[key][s] : 0)
        });
    });
    const ticks = [...tickSet];
    const traces = Object.keys(series).map(key => series[key]);
    return {
        traces: traces,
        tickvals: ticks,
        ticktext: truncate(ticks)
    };
};

const transformCountChart = function(data, config) {
    const keys = new Set(config.categories.map(x => x.key));
    let transformed_all = aggregateByMetricKey(data.results);
    const transformed = Object.keys(transformed_all)
        .filter(key => keys.has(key))
        .reduce((obj, key) => {
            obj[key] = transformed_all[key];
            return obj;
        }, {});
    const x = Object.keys(transformed).map(key => (model[key].short ? model[key].short : model[key].label));
    const series = {
        x: x,
        y: Object.values(transformed),
        type: "bar",
        marker: { color: colorbrewer.Blues[3][2] }
    };
    return {
        traces: [ series ],
        tickvals: x,
        ticktext: truncate(x)
    };
};

const transformValueCountChart = function(data, config) {
    const metric = config.metric;
    const transformed = {};
    data.results.map(x => x.metrics[0]).filter(x => x[metric] !== null).forEach(x => {
        if (x[metric] in transformed) {
            transformed[x[metric]] += 1;
        } else {
            transformed[x[metric]] = 1;
        }
    });
    const ordered = {};
    Object.keys(transformed).sort().forEach(function(key) {
        ordered[key] = transformed[key];
    });
    const x = Object.keys(ordered);
    const series = {
        x: x,
        y: Object.values(ordered),
        type: "bar",
        marker: { color: colorbrewer.Blues[3][2] }
    };
    return {
        traces: [ series ],
        tickvals: x,
        ticktext: truncate(x)
    };
};

const transformMetricLengthChart = function(data, config) {
    const x = data.results.map(y => y.name);
    const series = {
        x: x,
        y: data.results.map(y => y.metrics[0][config.metric].length),
        type: "bar",
        marker: { color: colorbrewer.Blues[3][2] }
    };
    return {
        traces: [ series ],
        tickvals: x,
        ticktext: truncate(x)
    };
};

const transformMetricYesNoChart = function(data, config) {
    const values = data.results.map(x => x.metrics[0][config.metric]);
    let yes;
    let no;
    let dontknow;
    let series;
    let x;
    if (config.dontknow) {
        yes = values.filter(v => v).length;
        no = values.filter(v => v === false).length;
        dontknow = values.filter(v => v === null).length;
        x = ["Yes", "No", "I don't know"];
        series = {
            x: x,
            y: [yes, no, dontknow],
            type: "bar",
            marker: { color: colorbrewer.Blues[3][2] }
        };
    } else {
        yes = values.filter(v => v).length;
        no = values.filter(v => !v).length;
        x = ["Yes", "No"];
        series = {
            x: x,
            y: [yes, no],
            type: "bar",
            marker: { color: colorbrewer.Blues[3][2] }
        };
    }
    return {
        traces: [ series ],
        tickvals: x,
        ticktext: truncate(x)
    };
};

/**
 * Aggregate by metric value.
 */
const aggregateByMetricValue = function(data) {
    const metrics = data.map(x => x.metrics);
    const transformed = {};
    metrics.map(x => x[0]).forEach(metric => {
        const keys = Object.keys(metric).filter(x => x !== "year");
        keys.forEach(key => {
            if (!(key in transformed)) {
               transformed[key] = {};
            }
            const value = metric[key];
            if (!(value in transformed[key])) {
               transformed[key][value] = 1;
            } else {
               transformed[key][value] = transformed[key][value] + 1;
            }
        });
    });
    return transformed;
};

/**
 * Aggregate metrics by metric key, for example to count how many countries have yes for a specific metric.
 */
const aggregateByMetricKey = function(data) {
    const metrics = data.map(x => x.metrics);
    const transformed = {};
    metrics.map(x => x[0]).forEach(metric => {
        const keys = Object.keys(metric).filter(x => x !== "year");
        keys.forEach(key => {
            if (!(key in transformed)) {
                transformed[key] = 0;
            }
            if (metric[key]) {
                transformed[key] = transformed[key] + 1;
            }
        });
    });
    return transformed;
};

export default {
    transformBarChart,
    transformRatingChart,
    transformCountChart,
    transformValueCountChart,
    transformSumBarChart,
    aggregateByMetricValue,
    aggregateByMetricKey,
    transformMetricLengthChart,
    transformMetricYesNoChart
};