const questions = {
    "8": {
        question: 8,
        title: "Which ministry(ies) is/are involved in ocean science in your country?",
        categories: [
            { key: "ministry_science_technology" },
            { key: "ministry_research_development" },
            { key: "ministry_education" },
            { key: "ministry_fisheries" },
            { key: "ministry_defense" },
            { key: "ministry_environment" },
            { key: "ministry_public_works" },
            { key: "ministry_planning" },
            { key: "ministry_culture" },
            { key: "ministry_sea" },
            { key: "ministry_transport" },
            { key: "ministry_energy" },
            { key: "ministry_health" },
            { key: "ministry_agriculture" },
            { key: "ministry_economy" },
            { key: "ministry_office_president" },
            { key: "ministry_office_prime_minister" },
            { key: "ministry_food_security" },
            { key: "ministry_others" }
        ]
    },
    "9": {
        question: 9,
        title: "Please list research institutions and universities or university faculties/departments specialized in ocean science in your country.",
        metric: "institutions"
    },
    "10": {
        question: 10,
        title: "Does your country have a national ocean science strategy?",
        metric: "national_strategy"
    },
    "11": {
        question: 11,
        title: "Please rate each ocean science category individually with regard to relevance for your country (from 5 highest to 1 lowest).",
        barmode: "stack",
        series: [
            { key: "category_rating_processes" },
            { key: "category_rating_climate" },
            { key: "category_rating_oceanhealth" },
            { key: "category_rating_humanhealth" },
            { key: "category_rating_bluegrowth" },
            { key: "category_rating_crust" },
            { key: "category_rating_technology" },
            { key: "category_rating_observation" },
        ]
    },
    "12": {
        question: 12,
        title: "Are the national ocean science activities, including human and technical capacities, published in a specialized report or part of national report since 2010?",
        metric: "activities_reported"
    },
    "13": {
        question: 13,
        title: "Does your country have a national communication strategy for ocean science, e.g. to ensure knowledge transfer among scienists, to the general public, politicians, policymakers and industries?",
        metric: "communication_strategy"
    },
    "14": {
        question: 14,
        heading: "Ministries spending",
        title: "Please specify the amount of money spent on ocean science by the respective ministries or the specific department and/or section involved in ocean science in each ministry identified in question 8.",
        metric: "ministry_spending_amount_usd"
    },
    "15": {
        question: 15,
        heading: "Institutions and universities spending",
        title: "Please specify the amount of money spent by the respective institutions and universities or university faculties/departments identified in question 9 (Part B) for ocean science activities per year.",
        metric: "institution_spending_amount_usd"
    },
    "16": {
        question: 16,
        heading: "Government spending",
        title: "Please provide the amount of money spent on ocean science in your country by governmental resources",
        barmode: "stack",
        subsets: {
            default: {
                series: [
                    {
                        key: "government_spending_central_amount_usd",
                    },
                    {
                        key: "government_spending_regional_amount_usd",
                    }
                ],
                fallbackSeries: [
                    {
                        key: "government_spending_total_amount_usd",
                    }
                ]
            }
        }
    },
    "17": {
        question: 17,
        heading: "Private spending",
        title: "Please provide the amount of money spent on ocean science in your country by institutions corresponding to private non-profit  and business enterprise  sectors.",
        barmode: "stack",
        subsets: {
            default: {
                series: [
                    {
                        key: "private_spending_nonprofit_amount_usd",
                    },
                    {
                        key: "private_spending_business_amount_usd",
                    }
                ],
                fallbackSeries: [
                    {
                        key: "private_spending_total_amount_usd",
                    }
                ]
            }
        }
    },
    "18": {
        question: 18,
        heading: "Financial contribution to international ocean science funding regimes",
        title: "Does your country contribute financially to international (global, regional) ocean science funding regimes, e.g. Horizon 2020, Belmont Forum, JPI Oceans, WIOMSA, Global Environmental Facility, UN bodies, Asia Pacific Network, Pacific Alliance?",
        metric: "contributes_international_funding",
        dontknow: true
    },
    "19": {
        question: 19,
        title: "If you answered yes to question 18, please specify the amount of money contributed to the individual collaboration programmes for each year.",
        metric: "contributes_international_funding_amount_usd"
    },
    "20": {
        question: 20,
        heading: "Financial support from international ocean science funding regimes",
        title: "Do the scientists in your country receive financial support from international (global, regional) ocean science funding regimes, e.g. Horizon 2020, Belmont Forum, JPI Oceans, WIOMSA, Global Environmental Facility, UN bodies, Asia Pacific Network, Pacific Alliance?",
        metric: "receives_international_funding",
        dontknow: true
    },
    "21": {
        question: 21,
        title: "If you answered yes to previous question, please specify the amount of money received from the individual collaboration programmes for each year.",
        metric: "receives_international_funding_amount_usd"
    },
    "22": {
        question: 22,
        heading: "Ocean science personnel",
        title: "Ocean science personnel by function - Headcounts (HC).",
        abstract: [
        ],
        barmode: "stack",
        subsets: {
            default: {
                series: [
                    {
                        key: "personnel_function_hc_researchers"
                    },
                    {
                        key: "personnel_function_hc_technicians"
                    },
                    {
                        key: "personnel_function_hc_other"
                    },
                    {
                        key: "personnel_function_hc_notspecified"
                    }
                ]
            }
        }
    },
    "23": {
        question: 23,
        title: "Ocean science personnel by function - Full-time equivalents (FTE).",
        abstract: [
        ],
        barmode: "stack",
        subsets: {
            default: {
                series: [
                    {
                        key: "personnel_function_fte_researchers"
                    },
                    {
                        key: "personnel_function_fte_technicians"
                    },
                    {
                        key: "personnel_function_fte_other"
                    },
                    {
                        key: "personnel_function_fte_notspecified"
                    }
                ]
            }
        }
    },
    "24": {
        question: 24,
        title: "Ocean science personnel by gender - Headcounts (HC).",
        abstract: [
        ],
        barmode: "stack",
        subsets: {
            default: {
                label: "Total personnel",
                series: [
                    {
                        key: "personnel_gender_hc_male"
                    },
                    {
                        key: "personnel_gender_hc_female"
                    }
                ]
            },
            researchers: {
                label: "Researchers",
                series: [
                    {
                        key: "personnel_gender_hc_researchers_male"
                    },
                    {
                        key: "personnel_gender_hc_researchers_female"
                    }
                ]
            }
        }
    },
    "25": {
        question: 25,
        title: "Please provide information about the age distribution and gender of researchers engaged in ocean science - Headcounts (HC).",
        barmode: "stack",
        subsets: {
            default: {
                label: "Age class under 25 years",
                series: [
                    {
                        key: "personnel_age_hc_under25_male"
                    },
                    {
                        key: "personnel_age_hc_under25_female"
                    }
                ],
                fallbackSeries: [
                    {
                        key: "personnel_age_hc_under25_total"
                    }
                ]
            },
            age25to34: {
                label: "Age class 25-34 years",
                series: [
                    {
                        key: "personnel_age_hc_25to34_male"
                    },
                    {
                        key: "personnel_age_hc_25to34_female"
                    }
                ],
                fallbackSeries: [
                    {
                        key: "personnel_age_hc_25to34_total"
                    }
                ]
            },
            age35to44: {
                label: "Age class 35-44 years",
                series: [
                    {
                        key: "personnel_age_hc_35to44_male"
                    },
                    {
                        key: "personnel_age_hc_35to44_female"
                    }
                ],
                fallbackSeries: [
                    {
                        key: "personnel_age_hc_35to44_total"
                    }
                ]
            },
            age45to54: {
                label: "Age class 45-54 years",
                series: [
                    {
                        key: "personnel_age_hc_45to54_male"
                    },
                    {
                        key: "personnel_age_hc_45to54_female"
                    }
                ],
                fallbackSeries: [
                    {
                        key: "personnel_age_hc_45to54_total"
                    }
                ]
            },
            age55to64: {
                label: "Age class 55-64 years",
                series: [
                    {
                        key: "personnel_age_hc_55to64_male"
                    },
                    {
                        key: "personnel_age_hc_55to64_female"
                    }
                ],
                fallbackSeries: [
                    {
                        key: "personnel_age_hc_55to64_total"
                    }
                ]
            },
            over64: {
                label: "Age class 65 years and more",
                series: [
                    {
                        key: "personnel_age_hc_over64_male"
                    },
                    {
                        key: "personnel_age_hc_over64_female"
                    }
                ],
                fallbackSeries: [
                    {
                        key: "personnel_age_hc_over64_total"
                    }
                ]
            },
        }
    },
    "26": {
        question: 26,
        title: "Please provide information about the qualification and gender of researchers engaged in ocean science - Headcounts (HC).",
        barmode: "stack",
        subsets: {
            default: {
                label: "Doctoral or equivalent (ISCED 8)",
                series: [
                    {
                        key: "personnel_qualification_hc_doctoral_male",
                    },
                    {
                        key: "personnel_qualification_hc_doctoral_female",
                    }
                ]
            },
            master: {
                label: "Master's or equivalent (ISCED 7)",
                series: [
                    {
                        key: "personnel_qualification_hc_master_male",
                    },
                    {
                        key: "personnel_qualification_hc_master_female",
                    }
                ]
            },
            bachelor: {
                label: "Bachelor's or equivalent (ISCED 6)",
                series: [
                    {
                        key: "personnel_qualification_hc_bachelor_male",
                    },
                    {
                        key: "personnel_qualification_hc_bachelor_female",
                    }
                ]
            },
            shortcycle: {
                label: "Short-cycle tertiary (ISCED 5)",
                series: [
                    {
                        key: "personnel_qualification_hc_shortcycle_male",
                    },
                    {
                        key: "personnel_qualification_hc_shortcycle_female",
                    }
                ]
            },
            other: {
                label: "All other qualifications (ISCED 4 and below)",
                series: [
                    {
                        key: "personnel_qualification_hc_other_male",
                    },
                    {
                        key: "personnel_qualification_hc_other_female",
                    }
                ]
            },
            notspecified: {
                label: "Not specified",
                series: [
                    {
                        key: "personnel_qualification_hc_notspecified_male",
                    },
                    {
                        key: "personnel_qualification_hc_notspecified_female",
                    }
                ]
            },
        }
    },
    "27": {
        question: 27,
        title: "Please provide the percentage of permanent employees of your country’s current ocean science personal (overall).",
        metric: "personnel_permanent"
    },
    "28": {
        question: 28,
        heading: "Ocean observation",
        title: "Does your country have ocean observation programme(s)/activity(ies)?",
        metric: "has_observation_programmes"
    },
    "29": {
        question: 29,
        title: "Does your country’s ocean observation include satellite observations?",
        metric: "satellite_observations"
    },
    "30": {
        question: 30,
        heading: "Vessels",
        title: "Does your country own and maintain research vessels?",
        metric: "owns_maintains_vessels"
    },
    "31": {
        question: 31,
        title: "Does your country own and maintain vessels partly used for ocean science?",
        metric: "owns_maintains_vessels_partly_used"
    },
    "32": {
        question: 32,
        title: "Do ocean science institutions, universities and/or faculties/departments of universities in your country involved in ocean science participate in efforts related to ships of opportunity/voluntary observing ships?",
        metric: "participates_ships_of_opportunity"
    },
    "33": {
        question: 33,
        title: "Do ocean science researchers have access to research vessels, which are not encompassed by questions 30-32?",
        metric: "access_other_vessels"
    },
    "34": {
        question: 34,
        title: "Please provide information about the number of research vessels, vessels partly used for ocean science (e.g. navy ships used for ocean science), and ships of opportunity (commercial vessels equipped with ocean observation equipment.) are operated by your nation. Further, please specify their length (if information is available).",
        barmode: "stack",
        subsets: {
            default: {
                series: [
                    {
                        key: "vessels_under10",
                    },
                    {
                        key: "vessels_coastal",
                    },
                    {
                        key: "vessels_regional",
                    },
                    {
                        key: "vessels_international",
                    },
                    {
                        key: "vessels_global",
                    }
                ]
            }
        }
    },
    "35": {
        question: 35,
        title: "Please list the name and IMO number of research vessels bigger than 55 m.",
        metric: "vessels_over_55m"
    },
    "36": {
        question: 36,
        title: "For research vessels, please specify the days at sea, distinguishing between Territorial Waters, the Exclusive Economic Zone and High Seas (days per year for 2017 or the last year with available data).",
        metric: "vessel_days_total"
    },
    "37": {
        question: 37,
        heading: "Emerging ocean science technology",
        title: "Do researchers in your country have access to the following new ocean science technologies.",
        categories: [
            { key: "technology_human_operated_vessel_submersible" },
            { key: "technology_surface_unmanned_vessel" },
            { key: "technology_remotely_operated_vessel" },
            { key: "technology_autonomous_underwater_vessel" },
            { key: "technology_underwater_glider" },
            { key: "technology_wave_glider" },
            { key: "technology_marine_drone" },
            { key: "technology_mooring_buoy" },
            { key: "technology_underwater_cable_system" },
            { key: "technology_doppler_rader_system" },
            { key: "technology_radiosonde_launcher" },
            { key: "technology_scientific_fish_finder" },
            { key: "technology_single_channel_hydrophone_array" },
            { key: "technology_multichannel_hydrophone_array" },
            { key: "technology_multicable_multichannel_hydrophone_array" },
            { key: "technology_air_gun_water_gun" },
            { key: "technology_subbottom_profiler" },
            { key: "technology_multi_narrow_beam_bottom_profiler" },
            { key: "technology_remotely_operated_drilling_equipment" },
            { key: "technology_remotely_operated_seafloor_sampler" },
            { key: "technology_multinet_plankton_sampler" },
            { key: "technology_deepsea_camera_system" },
            { key: "technology_stereoscopic_deepsea_camera_system" },
            { key: "technology_equipment_for_bathymetric_studies" },
            { key: "technology_auto_analyzer_chemical_analyses" },
            { key: "technology_xray_tomography" },
            { key: "technology_mass_spectrometer" },
            { key: "technology_fitc" },
            { key: "technology_radio_isotope_laboratory" },
            { key: "technology_dna_sequencer" },
            { key: "technology_deep_freezer" },
            { key: "technology_liquid_nitrogen_generator" },
            { key: "technology_other_onboard_lab_equipment" },
            { key: "technology_supercomputer" },
            { key: "technology_satellite_communication_antenna" }
        ]
    },
    "39": {
        question: 39,
        title: "Does your country have - (Multiple answers possible):",
        categories: [
            { key: "has_nodc" },
            { key: "has_adu" },
            { key: "has_obis_node" },
            { key: "has_marine_library" }
        ]
    },
    "40": {
        question: 40,
        title: "Is/are your county’s (data) centre(s) involved in the following types of collaboration? (Multiple answers possible):",
        categories: [
            { key: "collaboration_national" },
            { key: "collaboration_regional" },
            { key: "collaboration_international" }
        ]
    },
    "41": {
        question: 41,
        title: "Is/are your country’s data centre(s) collaborating with other IOC programmes, projects (in addition to IODE)? (Multiple answers possible):",
        categories: [
            { key: "collaboration_ioc_oceanscience" },
            { key: "collaboration_ioc_goos" },
            { key: "collaboration_ioc_tsunami" },
            { key: "collaboration_ioc_marinepolicy" },
            { key: "collaboration_ioc_dontknow" },
        ]
    },
    "42": {
        question: 42,
        title: "What observational data types are regularly collected and managed by your country’s data centre(s)? (Multiple answers possible):",
        categories: [
            { key: "observational_data_biological" },
            { key: "observational_data_physical" },
            { key: "observational_data_geo" },
            { key: "observational_data_chemical" },
            { key: "observational_data_pollutant" },
            { key: "observational_data_fisheries" },
            { key: "observational_data_socioeconomic" },
            { key: "observational_data_realtime" },
            { key: "observational_data_other" }
        ]
    },
    "43": {
        question: 43,
        title: "What data/information products do(es) your country’s data centre(s) provide to its/their clients? (Multiple answers possible):",
        categories: [
            { key: "data_products_metadata_access" },
            { key: "data_products_data_access" },
            { key: "data_products_library_access" },
            { key: "data_products_documents_access" },
            { key: "data_products_published_data" },
            { key: "data_products_communication" },
            { key: "data_products_gis" },
            { key: "data_products_portals" },
            { key: "data_products_model_data" },
            { key: "data_products_cdrom" },
            { key: "data_products_other" }
        ]
    },
    "44": {
        question: 44,
        title: "What services do(es) your county’s data centre(s) provide to its/their clients? (Multiple answers possible):",
        categories: [
            { key: "data_services_archival" },
            { key: "data_services_personal_repository" },
            { key: "data_services_cloud_computing" },
            { key: "data_services_vre" },
            { key: "data_services_webservices" },
            { key: "data_services_pids" },
            { key: "data_services_analysis" },
            { key: "data_services_visualization" },
            { key: "data_services_qc" },
            { key: "data_services_communication" },
            { key: "data_services_special" },
            { key: "data_services_standards" },
            { key: "data_services_other" }
        ]
    },
    "45": {
        question: 45,
        title: "Does your country's data centre(s) apply a data (sharing) policy on the management and sharing of data/information?",
        categories: [
            { key: "data_policy_institutional" },
            { key: "data_policy_national" },
            { key: "data_policy_international" }
        ]
    },
    "46": {
        question: 46,
        title: "Do your country’s data centre(s) comply with the FAIR data management criteria?",
        metric: "complies_fair"
    },
    "47": {
        question: 47,
        title: "Do(es) your country’s data centre(s) restrict access to data/information?",
        categories: [
            { key: "restricts_access_no" },
            { key: "restricts_access_types" },
            { key: "restricts_access_areas" },
            { key: "restricts_access_embargo" },
            { key: "restricts_access_other" },
        ]
    },
    "48": {
        question: 48,
        title: "Do(es) your country’s data centre(s) apply the IOC Oceanographic Data Exchange Policy adopted as Resolution IOC-XXII-6?",
        metric: "applies_ioc_policy"
    },
    "49": {
        question: 49,
        title: "Who are the clients and end users of the data, products or services provided by your country’s data centre(s)?",
        categories: [
            { key: "data_users_own_institution" },
            { key: "data_users_national_researchers" },
            { key: "data_users_researchers" },
            { key: "data_users_policy_makers_own_ministry" },
            { key: "data_users_policy_makers_other_ministry" },
            { key: "data_users_policy_makers" },
            { key: "data_users_military" },
            { key: "data_users_civil_protection" },
            { key: "data_users_private_sector" },
            { key: "data_users_school_children" },
            { key: "data_users_undergraduate" },
            { key: "data_users_general_public" },
            { key: "data_users_press" },
            { key: "data_users_social_media" },
            { key: "data_users_service_providers" },
            { key: "data_users_other" }        ]
    },
    "50": {
        question: 50,
        title: "Are data and information from your country’s data centre(s) contributing to international systems (meaning that you actively send data, or make data and metadata available, to e.g. the ICSU World Data System, GDACs, WMO Global Telecommunication System (GTS) or other such international systems?",
        metric: "data_contributing_international_systems"
    },
    "52": {
        question: 52,
        title: "Please rank your country’s top five specific capacity (development) needs (from 5 highest to 1 lowest).",
        barmode: "stack",
        series: [
            { key: "capacity_needs_rating_training" },
            { key: "capacity_needs_rating_specialized_training" },
            { key: "capacity_needs_rating_human" },
            { key: "capacity_needs_rating_sampling_equipment" },
            { key: "capacity_needs_rating_observation_equipment" },
            { key: "capacity_needs_rating_lab_observations" },
            { key: "capacity_needs_rating_computer" },
            { key: "capacity_needs_rating_conferences" },
            { key: "capacity_needs_rating_networking" },
            { key: "capacity_needs_rating_funding" },
            { key: "capacity_needs_rating_internet" }
        ]
    },
    "53": {
        question: 53,
        title: "Please rank the following types of technical training courses with respect to your national capacity development needs (from 5 highest priority to 1 lowest priority).",
        barmode: "stack",
        series: [
            { key: "training_needs_rating_research" },
            { key: "training_needs_rating_observation" },
            { key: "training_needs_rating_datamanagement" },
            { key: "training_needs_rating_sustainablemanagement" },
            { key: "training_needs_rating_communication" }
        ]
    },
    "54": {
        question: 54,
        title: "How would you rank your countries access to national and international scientific literature and information (e.g. peer reviewed journals, data bases)?",
        metric: "access_literature_information"
    },
    "55": {
        question: 55,
        title: "How many peer reviewed journals in national languages not indexed in Web of Science are published in your country?",
        metric: "journals_not_in_wos"
    },
    "56": {
        question: 56,
        title: "Does your country have special national efforts and mechanisms to absorb and keep graduates in ocean science related positions and activities (e.g. PhD programmes, young scientist funding resources, exchange programmes, early career support)?",
        metric: "efforts_keep_graduates"
    },
    "57": {
        question: 57,
        title: "Does your country have special national efforts and mechanisms to support female graduates and scientists in ocean science related positions and activities?",
        metric: "efforts_support_female_graduates"
    },
    "58": {
        question: 58,
        title: "What are the mechanisms that are in place to facilitate the participation of outside national experts in your country’s ocean science projects and policymaking?",
        categories: [
            { key: "outside_experts_guest_positions" },
            { key: "outside_experts_exchange_programmes" },
            { key: "outside_experts_board_memberships" },
            { key: "outside_experts_advisory_capacity" },
            { key: "outside_experts_others" },
            { key: "outside_experts_none" }
        ]
    },
    "59": {
        question: 59,
        title: "Does your country take part in bilateral support / training to increase scientific in particular ocean science related capacities, e.g. Fullbright scholarships, EEA and Norway Grants (EØS-midlene), Fish for Development and civil society 2018-2022?",
        metric: "participates_bilateral_support"
    },
    "60": {
        question: 60,
        title: "Does your country take part in regional/international support/training programmes, such as POGO, SCOR, OT (Ocean Teacher), Regional Network of Training and Research Centers (RTRC), ICES, PICES, IOC to increase scientific in particular ocean science related capacities?",
        metric: "participates_international_support"
    },
    "61": {
        question: 61,
        title: "Does your country have a national strategy to achieve the goals of the Agenda 2030 in particular the Sustainable Development Goal 14 and related targets?",
        categories: [
            { key: "agenda_2030_national_strategy" },
            { key: "agenda_2030_national_strategy_sdg_14" },
        ]
    },
    "62": {
        question: 62,
        title: "Does your country have a national focal point for the Sustainable Development Goal 14 and related targets?",
        metric: "has_sdg_14_focal_point"
    },
    "63": {
        question: 63,
        title: "Does your country have reporting mechanisms for the individual SDG 14 targets and indicators in place?",
        categories: [
            { key: "reporting_sdg_14_1" },
            { key: "reporting_sdg_14_2" },
            { key: "reporting_sdg_14_3" },
            { key: "reporting_sdg_14_4" },
            { key: "reporting_sdg_14_5" },
            { key: "reporting_sdg_14_6" },
            { key: "reporting_sdg_14_7" },
            { key: "reporting_sdg_14_a" },
            { key: "reporting_sdg_14_b" },
            { key: "reporting_sdg_14_c" }
        ]
    },
    "64": {
        question: 64,
        title: "Please select 5 ocean services and rank the below marine and coastal ecosystem services (adopted from Liquete et al. 2013) by their importance for your country.",
        barmode: "stack",
        series: [
            { key: "ocean_services_rating_food_provision" },
            { key: "ocean_services_rating_water_provision" },
            { key: "ocean_services_rating_biotic_materials" },
            { key: "ocean_services_rating_water_purification" },
            { key: "ocean_services_rating_air_quality" },
            { key: "ocean_services_rating_coastal_protection" },
            { key: "ocean_services_rating_climate_regulation" },
            { key: "ocean_services_rating_weather_regulation" },
            { key: "ocean_services_rating_ocean_nourishment" },
            { key: "ocean_services_rating_life_cycle_maintenance" },
            { key: "ocean_services_rating_biological_regulation" },
            { key: "ocean_services_rating_symbolic_values" },
            { key: "ocean_services_rating_recreation" },
            { key: "ocean_services_rating_cognitive_effects" }
        ]
    },
    "65": {
        question: 65,
        title: "Does your country have specific activities contributing to economies related to the sustainable use of ocean resources and/or developed a blue/ocean economy strategy?",
        metric: "blue_economy_strategy"
    },

};

export default questions;