import React, { useState } from "react";
import Downloader from "./Downloader";

export default function DataModal(props) {
    let url = "/api/metrics/pretty?";
    if (props.question) {
        url = url + "&question=" + props.question
    }
    if (props.region) {
        url = url + "&region=" + props.region
    }
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);
    const initialize = async function() {
        const res = await fetch(url);
        const data = await res.json();
        setData(data);
        setOpen(true);
        document.body.style.overflowY = "hidden";
    };
    const close = async function() {
        setOpen(false);
        document.body.style.overflowY = "auto";
    };
    const format = function(value) {
        if (value === true) return "yes";
        if (value === false) return "no";
        return value;
    };
    return <span className={props.className}>
        <button className="appearance-none rounded-none py-2 px-3 outline-none leading-tight border-0 bg-secondary hover:bg-secondary-lighter text-white" onClick={() => initialize()}>View data</button>
        {
            open &&
            <div className="modal-wrapper">
                <div className="modal">
                    <button className="appearance-none rounded-none py-2 px-3 outline-none leading-tight border-0 bg-secondary hover:bg-secondary-lighter text-white" onClick={() => close()}>Close</button>
                    <Downloader className="ml-3" region={props.region} question={props.question} />
                    <table className="mt-4 text-left">
                        <thead className="font-bold bg-gray-200">
                            <tr>
                                { data.header.map((h, i) => <th key={ i } className="py-2 px-3">{ h }</th>) }
                            </tr>
                        </thead>
                        <tbody className="bg-gray-100">
                            {
                                data.rows.map((row, i) => <tr key={ i }>{
                                    row.map((col, j) => <td key={ j } className="py-1 px-3 border-t border-gray-200">{ format(col) }</td>)
                                }</tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        }
    </span>
}